import BasePlugin from '../BasePlugin'

export default class AppointResponsibleEmployeeCommand extends BasePlugin {
  async execute () {
    let me = this
    let capitalConstructionObjectData = {
      'user_id': this.context.getModel().attr_Sotrudnic_,
      'capital_construction_object_id': this.context.getParentContext().getModel().id
    }

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AppointResponsibleEmployeeCommand`,
      capitalConstructionObjectData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
      this.context.$msgbox.close()
      me.context.$msgbox({
        type: 'info',
        message: 'Назначен новый ответственный за надзорное дело!'
      })
      this.context.getParentContext().getCard().$emit('cancelChanges')
    })
  }
}
